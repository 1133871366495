var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-filtered-text": "Nenhum registro encontrado",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.name) + " ")]
                },
              },
              {
                key: "cell(token)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.token) + " ")]
                },
              },
              {
                key: "cell(actions)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: { icon: "TrashIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(item.uuid)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EditIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.modalEdit(item)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-contract",
                "modal-class": "modal-create",
                title: _vm.titleModal,
                centered: "",
                "hide-footer": "",
                size: "lg",
              },
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitCreate.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Categoria do produto "),
                      ]),
                      _c("v-select", {
                        staticClass: "flex-grow-1 email-to-selector",
                        class: { "is-invalid": _vm.$v.item.category.$error },
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "uuid",
                          options: _vm.optionsProductCategories,
                          searchable: false,
                        },
                        model: {
                          value: _vm.item.category,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "category", $$v)
                          },
                          expression: "item.category",
                        },
                      }),
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "div",
                        { staticClass: "form-group mb-0" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Contrato "),
                          ]),
                          _c("editor", {
                            staticClass: "description-editor-1",
                            attrs: {
                              "api-key":
                                "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                              init: this.optionEditor({
                                height: 600,
                              }),
                            },
                            model: {
                              value: _vm.item.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "text", $$v)
                              },
                              expression: "item.text",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-card",
                    { staticClass: "my-2", attrs: { "no-body": "" } },
                    [
                      _c("b-card-header", { staticClass: "mb-0" }, [
                        _c("div", [
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-10" },
                              [
                                _c("b-card-title", { staticClass: "mb-1" }, [
                                  _vm._v(" Arquivos "),
                                ]),
                                _c("b-card-sub-title", [
                                  _vm._v(
                                    " Você pode adicionar 1 ou mais arquivos relacionado a este pedido de compra. "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-block btn-primary",
                                  staticStyle: {
                                    "padding-left": "5px !important",
                                    "padding-right": "5px !important",
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.selectFiles.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "bi bi-plus-circle" })]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("b-card-body", { staticClass: "p-2" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "form-group mb-0" }, [
                              _c("input", {
                                ref: "fileInput",
                                staticClass: "form-control-file hidden",
                                attrs: {
                                  type: "file",
                                  multiple: "",
                                  accept:
                                    ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                                },
                                on: { change: _vm.previewDocs },
                              }),
                              !_vm.files.length
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "py-3 text-center mb-0",
                                      staticStyle: {
                                        border: "1px solid rgb(222 222 222)",
                                        "border-radius": "4px",
                                        "border-style": "dashed",
                                        "font-size": "1.1rem",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "bi bi-file-earmark-text",
                                      }),
                                      _vm._v(" Selecionar arquivos "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "text-center mt-1 d-block",
                                        },
                                        [
                                          _vm._v(
                                            " Selcione apenas arquivos nos formatos: "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "label",
                                { staticClass: "d-block text-center mb-0" },
                                [
                                  _vm.files.length
                                    ? _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "list-group list-group-light",
                                          attrs: { id: "array-files" },
                                        },
                                        _vm._l(
                                          _vm.files,
                                          function (doc, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass:
                                                  "list-group-item border-0 rounded-5 mb-5-px text-left px-2",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-row" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "col-md-9 text-left",
                                                        staticStyle: {
                                                          "font-size": "0.9rem",
                                                        },
                                                        attrs: {
                                                          href: doc.url,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(doc.name))]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-2 text-center",
                                                        staticStyle: {
                                                          "font-size": "0.9rem",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              doc.size
                                                                ? _vm.$options.filters.bytesToMB(
                                                                    doc.size,
                                                                    2
                                                                  )
                                                                : "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-1 text-center",
                                                        staticStyle: {
                                                          "font-size": "0.9rem",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-danger btn-sm",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.deleteFile(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-trash3",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-7 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: { click: _vm.closeModal },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: _vm.textSubmit,
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }