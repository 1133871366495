<template>
  <div>
    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(name)="{ item }">
          {{ item.name }}
        </template>

        <template #cell(token)="{ item }">
          {{ item.token }}
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(item.uuid)"
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="modalEdit(item)"
            />
          </div>
        </template>
      </b-table>

      <b-modal
        id="modal-contract"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
        size="lg"
      >
        <b-form @submit.prevent="submitCreate">
          <div class="form-group">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Categoria do produto
            </label>
            <v-select
              label="title"
              item-text="title"
              v-model="item.category"
              item-value="uuid"
              class="flex-grow-1 email-to-selector"
              :options="optionsProductCategories"
              :searchable="false"
              :class="{ 'is-invalid': $v.item.category.$error }"
            />
          </div>

          <div class="form-group mb-0" v-if="false">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Contrato
            </label>
            <editor
              class="description-editor-1"
              api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
              v-model="item.text"
              :init="
                this.optionEditor({
                  height: 600,
                })
              "
            />
          </div>

          <!-- Upload de Arquivos Múltiplos -->
          <b-card no-body class="my-2">
            <b-card-header class="mb-0">
              <div>
                <div class="form-row">
                  <div class="col-md-10">
                    <b-card-title class="mb-1"> Arquivos </b-card-title>
                    <b-card-sub-title>
                      Você pode adicionar 1 ou mais arquivos relacionado a este
                      pedido de compra.
                    </b-card-sub-title>
                  </div>
                  <div class="col-md-2">
                    <button
                      @click.prevent="selectFiles"
                      type="button"
                      class="btn btn-block btn-primary"
                      style="
                        padding-left: 5px !important;
                        padding-right: 5px !important;
                      "
                    >
                      <i class="bi bi-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body class="p-2">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <input
                      ref="fileInput"
                      type="file"
                      multiple
                      class="form-control-file hidden"
                      @change="previewDocs"
                      accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    />

                    <p
                      v-if="!files.length"
                      class="py-3 text-center mb-0"
                      style="
                        border: 1px solid rgb(222 222 222);
                        border-radius: 4px;
                        border-style: dashed;
                        font-size: 1.1rem;
                      "
                    >
                      <i class="bi bi-file-earmark-text"></i>
                      Selecionar arquivos
                      <small class="text-center mt-1 d-block">
                        Selcione apenas arquivos nos formatos: <br />
                        xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf
                      </small>
                    </p>

                    <label class="d-block text-center mb-0">
                      <ul
                        class="list-group list-group-light"
                        id="array-files"
                        v-if="files.length"
                      >
                        <li
                          class="list-group-item border-0 rounded-5 mb-5-px text-left px-2"
                          v-for="(doc, index) in files"
                          :key="index"
                        >
                          <div class="form-row">
                            <a
                              :href="doc.url"
                              target="_blank"
                              class="col-md-9 text-left"
                              style="font-size: 0.9rem"
                              >{{ doc.name }}</a
                            >
                            <div
                              class="col-md-2 text-center"
                              style="font-size: 0.9rem"
                            >
                              {{
                                doc.size
                                  ? $options.filters.bytesToMB(doc.size, 2)
                                  : "-"
                              }}
                            </div>
                            <div
                              class="col-md-1 text-center"
                              style="font-size: 0.9rem"
                            >
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                @click.prevent="deleteFile(index)"
                              >
                                <i class="bi bi-trash3"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </label>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>

          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button
                type="button"
                @click="closeModal"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BBadge,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    BCard,
    BBadge,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    ButtonsActionsFooter,
    editor: Editor,
  },
  data() {
    return {
      fileInput: "",
      files: [],
      removedFiles: [],
      uuid: null,
      textSubmit: "",
      submited: false,
      filters: {
        currentPage: 1,
        perPage: 100,
        category: "",
      },
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "productCategory.name",
          label: "Categoria",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      optionsProductCategories: [],
      titleModal: "",
      item: {
        text: "",
        category: "",
        medias: [],
      },
    };
  },
  validations: {
    item: {
      category: {
        required,
      },
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-contract", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Contrato";
      this.clearForm();
      this.$bvModal.show("modal-contract");
    });

    this.fetchProductCategories();
  },
  beforeDestroy() {
    this.$root.$off("open:modal-contract");
  },
  methods: {
    selectFiles() {
      this.$refs.fileInput.click();
    },
    closeModal() {
      this.$bvModal.hide("modal-contract");
    },
    previewDocs(event) {
      if (event.target.files.length) {
        this.files = [...this.files, ...event.target.files];
        this.$refs.fileInput.value = "";
      }
    },
    deleteFile(index) {
      const removed = this.files.splice(index, 1)[0];
      if (removed.isExisting) {
        this.removedFiles.push(removed.uuid);
      }
    },
    async fetchProductCategories() {
      this.optionsProductCategories = [];
      this.$store.dispatch("Product/categories").then((res) => {
        this.optionsProductCategories = res.data;
      });
    },
    modalEdit(item) {
      console.log(item);
      this.textSubmit = "Salvar";
      this.titleModal = "Editar Contrato";
      this.clearForm();

      this.item = {
        category: {
          code: item.productCategory.uuid,
          title: item.productCategory.name,
        },
        text: "",
      };

      this.files = item.medias.map((media) => {
        return {
          name: media.title || media.filename.split("/").pop(),
          size: 0,
          url: media.url,
          uuid: media.uuid,
          isExisting: true,
        };
      });

      this.uuid = item.uuid;
      this.$bvModal.show("modal-contract");
    },
    submitCreate() {
      this.$v.item.$touch();

      if (!this.$v.item.$error) {
        this.submited = true;

        const formData = new FormData();
        formData.append("text", this.item.text);
        formData.append("category", this.item.category.code);

        this.files.forEach((file) => {
          if (file instanceof File) {
            formData.append("files[]", file);
          }
        });

        this.removedFiles.forEach((uuid) => {
          formData.append("remove_files[]", uuid);
        });

        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Contract/create", formData)
            .then(() => {
              this.$v.item.$reset();
              this.getData();
              this.notifyDefault("success");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-contract");
                this.clearForm();
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Contract/edit", { uuid: this.uuid, data: formData })
            .then(() => {
              this.$v.item.$reset();
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-contract");
                this.clearForm();
              });
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    clearForm() {
      this.item = {
        text: "",
        category: "",
      };
      this.uuid = null;
      this.files = [];
      this.removedFiles = [];
      this.$v.$reset();
    },
    async getData() {
      this.dataItems = [];
      this.$store
        .dispatch("Contract/all", {
          page: this.filters.currentPage,
          limit: this.filters.perPage,
          category: this.filters.category,
        })
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.filters.currentPage = res.meta.current_page;
          }
        });
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Contract/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
};
</script>
